@use '../../../root' as v;
.row-horizon{
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 10px;
}

.categories {
    cursor: pointer;
    text-align: center;
    margin: 2px 2px;
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid gray;
    border-radius: 10px;
    color: #6B6B6B;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 500;
    padding-bottom: 7px;
    border-bottom: 3px solid v.$primary;
}

.categories.active {
    background-color: v.$primary;
    color: white;
    border-bottom: 3px solid v.$secondary;
}

.cart-list{
    height:200px;
  overflow-y: scroll;
}
.header-table2 {
    position: sticky;
    top:0;
    background: #444444;
    color: white;
}

.card-products{
    min-height: 150px;
    min-width: 150px;
    color: white;
    background: v.$secondary;
}

.card-products-text{
    color: white;
    font-size: 1rem;
}

.colum-cart{
    height: 810px;
    
}

.colum-products{
    height: 810px;
    overflow-y: scroll;
}