@use '../../root' as v;
.sidebar{
        margin-left: -15rem;
        width: 15rem;
        padding-bottom: 0;
        height: 100%;
        top: 3.3rem;
        padding-top: 0;
        z-index: 6;
        background-color: #ffffff;
        transition: all .2s ease;
        box-shadow: 0rem 0.9375rem 1.875rem 0rem rgba(0, 0, 0, 0.02);
       position: fixed;
    
}

.sidebar-styles{
    margin-top: 0;
    height: 100%;
    background: v.$mainBg;

}


.sidebar-styles .nav-link{
    color: black;
    box-shadow: 0rem 0.9375rem 1.875rem 0rem rgba(0, 0, 0, 0.02);
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left:10px;
    margin-right:10px;
}

.sidebar-styles .nav-link.link-nav-active{
    color: white;
    background: v.$primary;
    position: relative;
    //margin:10px
}

body.sb-sidenav-toggled .sidebar {
    margin-left: 0;
}