$mainBg: #ffffff;
$primary: #1f9cd9;
$secondary: #2e3646;

$success : #51ac45;
$danger : #ed1b23;
$warning : #fbd547;
$default: #030706;

.btn-primary {
    background-color: $primary !important;
    color: white !important
}

.btn-secondary {
    background-color: $secondary !important;
    color: white !important
}

.btn-success {
    background-color: $success !important;
    color: black !important
}

.btn-info {
    background-color: $secondary !important;
    color: black !important
}

.btn-danger {
    background-color: #ef4747 !important;
    color: black !important
}

.btn-label {
    margin-right: 10px;
    padding: 6px 12px;
    left: 0;
    position: relative;
    background: rgba(0, 0, 0, 0.15);
}

.btn-text {
    flex: 1;
}

.btn-labeled {
    display: flex;
    flex-direction: row;
    padding: 0px;
    align-items: center;
}

.bg-color-primary {
    background-color: $primary;
    color: white;
}

.bg-color-secondary {
    background-color: $secondary;
    color: white;
}