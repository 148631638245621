@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background: #ffffff !important
  
}

.pointer{
  cursor: pointer;
}

.activeTable{
  background-color: #ffc1078c !important;
}

