#wrapper{
    position: relative;
    height: 100vh;
}

.content-body {
    padding-top: 3.3rem;
    z-index: 0;
    transition: all .2s ease;
    margin-left: 0rem;
  }

body.sb-sidenav-toggled .content-body {
    margin-left: 15rem;
}