@use '../../root' as v;
.nav-header {
    position: fixed;
    border-top-left-radius: 0;
  }

.nav-header{
    width: 100%;
    display: inline-block;
    text-align: left;
    position: absolute;
    top: 0;
    background-color: v.$mainBg;
    transition: all .2s ease;
    z-index: 5;
}

.nav-control {
    cursor: pointer;
    position: absolute;
    left: 190px;
    text-align: center;
    top: 40%;
    transform: translateY(-50%);
    z-index: 9999;
    font-size: 1.4rem;
    padding: 0.125rem 0.5rem 0;
    border-radius: 0.125rem;
  }

.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #94b2eb;
    z-index: 3;
    
  }

