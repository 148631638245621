@use '../../root' as v;

.card-stats{
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    border-radius: 20px;
    border-color: transparent;
    background-color: v.$secondary !important;
}

.card-stats-text{
    color: white;
    font-weight: bold;
    font-size: 22px;
}

.card-stats-money{
    color: white;
    font-weight: bold;
    font-size: 26px;
}